import Vue from 'vue'

Vue.component('field-options-scores-component', {
  template: '#field_options_scores_component',
  props: {
    show: Boolean,
    currentField: Object
  },
  computed: {
    activeOptions: function () {
      // Optimize: Verify fields on vueJS
      if (this.currentField.weight == null) {
        this.currentField.weight = "1.0";
      }
      return this.currentField.field_options_attributes.filter(function (option) {
      if(option.score == null) {
        option.score = "0.0";
      }
        return option._destroy != true;
      })
    }
  },
  methods: {
    forceValidation: function() {
      //implementação vazia, apenas para atender parent ao salvar
      //TODO ajustar para que isso não seja mais necessário.
    },
    
    close: function() {
      this.show = false;
    }
  }
})