import { useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';

import CalendarLeftSection from './work/components/CalendarLeftSection';
import TotalWorks from './work/components/TotalWorks';
import WorksFullCalendar from './work/components/WorksFullCalendar';
import CalendarFooter from './work/components/CalendarFooter';
import { Box } from '@mui/material';

const formatTimeZone = (tz) => {
  if (!tz || tz === 'Brasilia') {
    return 'America/Sao_Paulo';
  }

  return tz;
};

const Calendar = ({ canUpdateWork, userTimeZone }) => {
  let calendarRef = useRef(null);
  const [leftSectionVisible, setLeftSectionVisible] = useState(false);

  const updateLeftSectionVisibility = () => {
    setLeftSectionVisible(!leftSectionVisible);
  };

  const leftSectionObject = {
    leftSectionVisible: leftSectionVisible,
    updateLeftSectionVisibility: updateLeftSectionVisibility,
  };

  const userCanUpdateWork = canUpdateWork === 'true';

  const formattedTimeZone = formatTimeZone(userTimeZone);

  return (
    <>
      <Box maxHeight={937} display={'flex'} position={'relative'}>
        {userCanUpdateWork && (
          <CalendarLeftSection
            calendarRef={calendarRef}
            leftSection={leftSectionObject}
          />
        )}

        <div className="calendar-component">
          <TotalWorks calendarRef={calendarRef} />
          <WorksFullCalendar
            calendarRef={calendarRef}
            userTimeZone={formattedTimeZone}
            userCanUpdateWork={userCanUpdateWork}
            leftSection={leftSectionObject}
          />
        </div>
      </Box>
      <CalendarFooter />
    </>
  );
};

const el = document.getElementById('calendar-container');
if (el !== null) {
  const root = createRoot(el);

  root.render(
    <Calendar canUpdateWork={_canUpdateWork} userTimeZone={_userTimeZone} />
  );
}
