import { WorkStatus } from '../types';

export const eventCSSClassByStatus = (workStatus: WorkStatus) => {
  const cssClassesByStatus = {
    not_started: 'work-event-not-started',
    started: 'work-event-started',
    finished: 'work-event-finished',
    reviewed: 'work-event-reviewed',
  };

  return cssClassesByStatus[workStatus] as string;
};

export const lateEvent = (status: WorkStatus, endTime: string) => {
  const endDateTime = new Date(endTime).getTime();
  const now = Date.now();
  const statusCanBeLate = status === 'not_started' || status === 'started';

  if (statusCanBeLate && endDateTime < now) {
    return true;
  }

  return false;
};

export const eventEditable = (workStatus: WorkStatus) => {
  if (workStatus === 'finished' || workStatus === 'reviewed') {
    return false;
  }

  return true;
};
