import React from 'react';
import { useEffect, useState, RefObject } from 'react';

interface TotalWorksProps {
  calendarRef: RefObject<any>;
}

const TotalWorks: React.FC<TotalWorksProps> = ({ calendarRef }) => {
  const [visibleWorksCount, setVisibleWorksCount] = useState<number>(0);

  useEffect(() => {
    const updateVisibleWorksCount = () => {
      const calendarApi = calendarRef.current.getApi();
      const calendarStartDate = new Date(calendarApi.view.activeStart);
      const calendarEndDate = new Date(calendarApi.view.activeEnd);

      const visibleEvents = calendarApi
        .getEvents()
        .reduce((acc: number, curr: any) => {
          const currStart = new Date(curr.start);
          const currEnd = new Date(curr.end);

          if (currStart > calendarEndDate || currEnd < calendarStartDate) {
            return acc;
          }

          return acc + 1;
        }, 0);

      setVisibleWorksCount(visibleEvents);
    };

    const calendarApi = calendarRef.current.getApi();
    calendarApi.on('eventsSet', updateVisibleWorksCount);

    return () => {
      calendarApi.off('eventsSet', updateVisibleWorksCount);
    };
  }, [calendarRef]);

  const applyVisibleWorksCountTitle = (count: number) => {
    if (count === 0) {
      return 'Nenhuma atividade encontrada';
    } else if (count === 1) {
      return 'Exibindo 1 atividade';
    } else {
      return `Exibindo ${count} atividades`;
    }
  };

  return (
    <div>
      <p className="total-works-count">
        {applyVisibleWorksCountTitle(visibleWorksCount)}
      </p>
    </div>
  );
};

export default TotalWorks;
