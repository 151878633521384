const updateFilterByWorkStatus = () => {
  const filtersNotAvailableByStatus = {
    "not_started": ['work-finishedOption'],
    "started": ['work-finishedOption'],
    "finished": [],
    "reviewed": []
  };

  document.querySelectorAll('.work-input input[type="text"]').forEach((el) => {
    el.disabled = false;
    el.parentElement.classList.remove('is-disabled');
  });

  document.querySelectorAll('.work-input select').forEach((el) => {
    el.disabled = false;
    el.parentElement.classList.remove('is-disabled');
  });

  const checkedBoxes = document.querySelectorAll('#work-filter-actions .statuses-list input[type="checkbox"]:checked');

  let lastElem = checkedBoxes[checkedBoxes.length - 1];
  lastElem = lastElem ? lastElem.id : 'not_started';

  const filtersNotAvailable = filtersNotAvailableByStatus[lastElem] || [];

  filtersNotAvailable.forEach((filterID) => {
    const filter = document.getElementById(filterID);
    filter.disabled = true;
    filter.parentElement.classList.add('is-disabled');
  });
};

$(document).on('ready', function () {
  const statusesCheckBox = document.querySelectorAll('#work-filter-actions .statuses-list input[type="checkbox"]');

  if (statusesCheckBox.length > 0) {
    updateFilterByWorkStatus();

    statusesCheckBox.forEach((checkBox) => {
      checkBox.addEventListener('click', () => updateFilterByWorkStatus());
    });
  }
});
