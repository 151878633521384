import { createRoot } from 'react-dom/client';
import { AiAssistComponent } from "../components/AiAssistComponent"

const renderAi = () => {
  const el = document.getElementById('ai_assist-button')
  if (el !== null) {
    const root = createRoot(el)
    root.render(
      <AiAssistComponent formFillID={el.dataset.form_fill_id} />
    )
  }
}

export default renderAi
