import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/pt-br';

import { EventApi } from '@fullcalendar/core/index.js';

import {
  Box,
  List,
  ListItem,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { updateWork } from '../../api/works';

import { ErrorMessage, WorkDurationArg, EditWorkDurationArg } from '../types';

interface EditWorkDurationProps {
  editWorkObject: EditWorkDurationArg;
}

const dateTimePickerTheme = createTheme({
  palette: {
    primary: {
      main: '#01a32d',
    },
  },
});

dayjs.extend(utc);
dayjs.extend(timezone);

const EditWorkDuration: React.FC<EditWorkDurationProps> = ({
  editWorkObject,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [workEvent, setWorkEvent] = useState<EventApi | null>(null);
  const [workDuration, setWorkDuration] = useState<WorkDurationArg>({
    start: undefined,
    end: undefined,
    startError: null,
    endError: null,
  });

  const componentStyle = {
    display: 'block',
    paddingRight: '17px',
    background: 'rgb(0, 0, 0, 0)',
  };

  const { oldEventStart, oldEventEnd, userTimeZone } = editWorkObject;

  useEffect(() => {
    if (editWorkObject.calendarInfo) {
      setModalOpen(true);
      setWorkEvent(editWorkObject.calendarInfo.event);

      const startDateTime = dayjs.tz(
        editWorkObject.calendarInfo.event.start?.toISOString(),
        userTimeZone
      );
      let endDateTime: Dayjs | undefined = undefined;

      if (editWorkObject.hasEndTime) {
        endDateTime = dayjs.tz(
          editWorkObject.calendarInfo.event.end?.toISOString(),
          userTimeZone
        );
      }

      setWorkDuration({
        start: startDateTime,
        end: endDateTime,
        startError: Boolean(startDateTime) ? '' : 'Campo obrigatório',
        endError: Boolean(endDateTime) ? '' : 'Campo obrigatório',
      });
    }
  }, [editWorkObject.calendarInfo]);

  const handleStartDateChange = (value: Dayjs | null) => {
    let startError: ErrorMessage = null;
    let endError = workDuration.endError;

    if (!value) {
      startError = 'Campo obrigatório';
    }

    if (value && workDuration.end) {
      if (workDuration.end <= value) {
        startError = 'Data inicial não pode ser maior ou igual que a final';
      } else {
        endError = null;
      }
    }

    setWorkDuration({
      ...workDuration,
      start: value || undefined,
      startError: startError,
      endError: endError,
    });
  };

  const handleEndDateChange = (value: Dayjs | null) => {
    let endError: ErrorMessage = null;
    let startError = workDuration.startError;

    if (!value) {
      endError = 'Campo obrigatório';
    }

    if (value && workDuration.start) {
      if (workDuration.start >= value) {
        endError = 'Data final não pode ser menor ou igual que a inicial';
      } else {
        startError = null;
      }
    }

    setWorkDuration({
      ...workDuration,
      end: value || undefined,
      endError: endError,
      startError: startError,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (Boolean(workDuration.startError) || Boolean(workDuration.endError)) {
      return alert(
        'Não foi possível concluir ação. Preencha campos obrigatórios e tente novamente'
      );
    }

    // Verificar como driblar tipagem
    const newStart = (workDuration.start as Dayjs).format();
    const newEnd = (workDuration.end as Dayjs).format();

    const worBody = {
      work: {
        start_time: newStart,
        end_time: newEnd,
      },
    };

    updateWork({ id: workEvent?.id, body: worBody }).then(
      () => {
        editWorkObject.successCallback(newStart, newEnd);
        setModalOpen(false);
      },
      (e) => {
        alert(e.message);
      }
    );
  };

  const handleCancel = () => {
    setModalOpen(false);
    editWorkObject.failureCallback();
  };

  const formatDate = (date: string | null) => {
    if (!date) {
      return '--/--/---- --:—';
    }

    const dateObj = new Date(date);
    const dateStr = new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: userTimeZone,
    }).format(dateObj || undefined);

    const timeStr = new Intl.DateTimeFormat('pt-BR', {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: userTimeZone,
    }).format(dateObj || undefined);

    return `${dateStr} ${timeStr}`;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      {modalOpen && workEvent && (
        <ThemeProvider theme={dateTimePickerTheme}>
          <div
            className="modal fade in"
            id="editWorkDuration"
            style={componentStyle}
          >
            <form className="modal-dialog" onSubmit={handleSubmit}>
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="editWorkDurationLabel">
                    Editar atividade
                  </h4>
                </div>
                <div className="modal-body">
                  <Box
                    sx={{
                      color: '#464646',
                      marginBottom: '16px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '12px',
                      }}
                    >
                      Título da atividade
                    </Typography>
                    <Typography sx={{ fontSize: '16px' }}>
                      {workEvent.title}
                    </Typography>
                  </Box>
                  <div>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        lineHeight: '24px',
                        color: '#757575',
                      }}
                    >
                      Como foi planejado:
                    </Typography>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        padding: '0 32px',
                        color: '#757575',
                        marginBottom: '24px',
                      }}
                    >
                      <ListItem
                        sx={{
                          display: 'list-item',
                          padding: 0,
                        }}
                      >
                        Data e hora inicial: {formatDate(oldEventStart)}
                      </ListItem>
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>
                        Data e hora final: {formatDate(oldEventEnd)}
                      </ListItem>
                    </List>
                  </div>
                  <Typography
                    sx={{
                      color: '#01A32D',
                      fontSize: '16px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textAlign: 'left',
                      marginBottom: '24px',
                    }}
                  >
                    Quando será realizado?
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '16px' }}>
                    <DateTimePicker
                      className="produttivo-mui-picker"
                      label="Data e hora inicial"
                      name="work[start_time]"
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      timezone={userTimeZone}
                      onChange={handleStartDateChange}
                      value={workDuration.start}
                      slotProps={{
                        textField: {
                          error: Boolean(workDuration.startError),
                          helperText: workDuration.startError,
                        },
                      }}
                    />
                    <DateTimePicker
                      label="Data e hora final"
                      name="work[end_time]"
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      timezone={userTimeZone}
                      onChange={handleEndDateChange}
                      value={workDuration.end}
                      minDate={workDuration.start}
                      slotProps={{
                        textField: {
                          error: Boolean(workDuration.endError),
                          helperText: workDuration.endError,
                        },
                      }}
                    />
                  </Box>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="mdl-button button-mid button-cancel"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="mdl-button button-primary button-mid"
                    disabled={
                      Boolean(workDuration.startError) ||
                      Boolean(workDuration.endError)
                    }
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-backdrop fade in"></div>
        </ThemeProvider>
      )}
    </LocalizationProvider>
  );
};

export default EditWorkDuration;
