import React, { useRef, useEffect } from "react";
import ClearIcon from '@mui/icons-material/Clear';

interface DialogProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  title: String;
  isBeta?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({ children, isOpen, onClose, title, isBeta }) => {
  const modalRef = useRef<HTMLDialogElement | null>(null);

   useEffect(() => {
    if (isOpen)
      return modalRef.current?.showModal()
    modalRef.current?.close()
  }, [isOpen]);

  return (
    <dialog ref={modalRef} className="dialog">
      <div className="dialog__content">
        <div className="header">
          <span className="header__text">{title}</span>
          {isBeta && <span className="header__text--beta">Beta</span>}
          <button className="header__close" onClick={onClose}>
            <ClearIcon />
          </button>
        </div>
        {children}
      </div>
    </dialog>
  )
}
