/**
 * Created by marfan on 31/05/17.
 */
import Vue from 'vue'

Vue.directive('selectize', {
  twoWay: true,
  priority: -1,
  params: ['field_index'],
  bind: function() {
    var that = this;
    // console.log('binding..');
    $(that.el)
      .selectize(
        {
          persist:          false,
          options:          that.vm.field_types,
          valueField:       'value',
          labelField:       'text',
          searchField:      ['text'],
          placeholder:      "Selecione o tipo da resposta",
          render: {
            item: function(item, escape) {
              var label = item.text;
              return '<div class="selectize-item-content">' +
                '<span class="selectize-item-label">' + label + '</span>' +
                '</div>';
            },
            option: function(item, escape) {
              var label   = item.text;
              var caption = item.description;
              var disabled = item.disabled;
              var disabledAttr = disabled === true ? 'disabled tabindex="-1" style="pointer-events: none;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;opacity: 0.4;"' : ''
              return '<div class="selectize-option-content" ' + disabledAttr + '>' +
                '<span class="selectize-option-label" disabled>' + label   + '</span>' +
                '<span class="selectize-option-caption" disabled>' + caption + '</span>' +
                '</div>';
            }
          },
          onDropdownClose: function(dropdown) {
            that.vm.fieldsAttributes[that.params.field_index].field_type_touched = true;
          }
        }
      )
      .on('change', function(e) {
        that.set(this.value);
        // console.log(that.params.field_index)
        that.vm.setDefaultFieldOptions(that.vm.fieldsAttributes[that.params.field_index]);
      });
  },
  update: function(newValue)  {
    // console.log('updating..', newValue);
    this.el.selectize.setValue(newValue);
  },
  unbind: function () {
    // console.log('unbiding..');
    this.el.selectize.destroy();
  }
});
