import Vue from 'vue'

Vue.component('field-categories-component', {
  template: '#field_categories_component',
  props: {
    show: Boolean,
    editMode: Boolean,
    savingMode: Boolean,
    currentField: Object,
    currentFieldCategories: Object
  },
  watch: {
    show: function(val) {
      if (val) {
        this.prepareToShowView();
      }
    }
  },
  methods: {
    forceValidation: function() {
      //implementação vazia, apenas para atender parent ao salvar
      //TODO ajustar para que isso não seja mais necessário.
    },
    
    prepareToShowView: function() {
      this.convertFieldAttributesToView();
      this.editMode = false;
      this.savingMode = false;
    },
    
    convertFieldAttributesToView: function() {
      this.currentFieldCategories = [];
      
      var that = this;

      _categories.forEach(function(category) {
        
        var fieldCategoriesAttributes = that.currentField.field_categories_attributes;

        var fieldCategoriesWithSameCategoryId = fieldCategoriesAttributes.filter(function(fieldCategory) {
          return fieldCategory.category_id == category.id;
        });
        
        var fieldCategory = null;
        
        if (fieldCategoriesWithSameCategoryId.length > 0) {
          fieldCategory = fieldCategoriesWithSameCategoryId[0];
        }
        
        var convertedFieldCategory = {
          category_id: category.id,
          category_name: category.name,
          weight: 1.0,
          selected: false,
          edit_mode: false
        };

        if (fieldCategory) {
          convertedFieldCategory.selected = !fieldCategory._destroy;
          convertedFieldCategory.id = fieldCategory.id;
          convertedFieldCategory.weight = fieldCategory.weight;
        }

        that.currentFieldCategories.push(convertedFieldCategory);
      });
    },
    
    convertFieldAttributesFromView: function() {
      var that = this;
      
      this.currentFieldCategories.forEach(function(convertedFieldCategory) {
        var fieldCategoriesAttributes = that.currentField.field_categories_attributes;

        var fieldCategoriesWithSameCategoryId = fieldCategoriesAttributes.filter(function(fieldCategory) {
          return fieldCategory.category_id == convertedFieldCategory.category_id;
        });
        
        var fieldCategory;
        
        if (fieldCategoriesWithSameCategoryId.length > 0) {
          fieldCategory = fieldCategoriesWithSameCategoryId[0];
        } else {
          fieldCategory = {
            category_id: convertedFieldCategory.category_id
          };
          
          fieldCategoriesAttributes.push(fieldCategory);
        }
          
        fieldCategory._destroy = !convertedFieldCategory.selected;
        fieldCategory.weight = convertedFieldCategory.weight;
      });
    },
    
    newCategory: function() {
      this.currentFieldCategories.push({
        weight: 1.0,
        selected: true,
        edit_mode: true
      });
      
      this.editMode = true;
    },
    
    editCategory: function(fieldCategory) {
      this.editMode = true;
      fieldCategory.edit_mode = true;
    },
    
    saveCategory: function(fieldCategory) {
      this.savingMode = true;
      
      var lastIndex = this.currentFieldCategories.length - 1;
      var lastFieldCategory = this.currentFieldCategories[lastIndex];
      
      var category = {
        id: fieldCategory.category_id,
        account_id: _form.account_id,
        name: fieldCategory.category_name,
        status: "active"  
      }
      
      var isNewCategory = (category.id == null);
      var saveMethod;
      var saveUrl;
      
      if (isNewCategory) {
        saveMethod = "POST";
        saveUrl = "/categories";
      } else {
        saveMethod = "PUT";
        saveUrl = "/categories/" + category.id;
      }
      
      var that = this;
      
      $.ajax({
        method: saveMethod, 
        data: JSON.stringify({ category: category }), 
        url: saveUrl, 
        dataType: 'json', 
        contentType: 'application/json; charset=utf-8',
        success: function(savedCategory) { 
          that.editMode = false;
          that.savingMode = false;
          
          if (isNewCategory) {
            fieldCategory.category_id = savedCategory.id;
            _categories.push(savedCategory);
          } else {
            _categories.forEach(function(existentCategory) {
              if (existentCategory.id == category.id) {
                existentCategory.name = category.name;
                return;
              }
            });
          }
          
          fieldCategory.edit_mode = false;
        },
        error: function(response) {
          that.savingMode = false;
          
          if (response.status == 422) {
            // TODO: deve existir outra maneira mais esperta de se fazer esse alerta
            var errors = _reposta.responseJSON.errors;
            
            if (errors.name.length > 0) {
              alert(errors.name[0]);
            } else {
              alert("Informações inválidas para a categoria.");
            }
          } else {
            alert("Ocorreu um erro e a categoria não pode ser salva.");
          }
        }
      });
    },
    
    destroyCategory: function(fieldCategoryToRemove) {
      var isNewCategory = (fieldCategoryToRemove.category_id == null);
      
      if (isNewCategory) {
        /* Se é novo ainda não foi persistido */
        this.currentFieldCategories.splice(this.currentFieldCategories.indexOf(fieldCategoryToRemove), 1);
        this.editMode = false;
        return;
      }
      
      var confirmed = confirm("Isso irá excluir a categoria de todas as questões. Tem certeza se deseja remover?");
      
      if (confirmed) {
        var persistedCategory = (fieldCategoryToRemove.category_id != null);
        
        this.savingMode = true;
      
        var that = this;
      
        $.ajax({
          method: "DELETE", 
          url: "/categories/" + fieldCategoryToRemove.category_id, 
          dataType: 'json', 
          contentType: 'application/json; charset=utf-8',
          success: function(response) { 
            /* Remove da lista da tela */
            that.currentFieldCategories = that.currentFieldCategories.filter(function(fieldCategory) {
              return fieldCategory.category_id != fieldCategoryToRemove.category_id;
            });

            /* Remove as categorias do field */
            that.currentField.field_categories_attributes = that.currentField.field_categories_attributes.filter(function(fieldCategory) {
              return fieldCategory.category_id != fieldCategoryToRemove.category_id;
            });
          
            /* Remove da lista das categorias */
            _categories = _categories.filter(function(category) {
              return category.id != fieldCategoryToRemove.category_id;
            });
          
            that.editMode = false;
            that.savingMode = false;
          },
          error: function(response) {
            that.savingMode = false;
            alert("Ocorreu um erro e a categoria não pode ser excluída.");
          }
        });
      }
    },
    
    close: function() {
      this.convertFieldAttributesFromView();
      this.show = false;
    }
    
  }
})