import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

var importModal
var formFileImport = document.getElementById('file-import')
if(formFileImport) {
    importModal = new Vue({
        http: {
            root: 'http://localhost:3000/'
        },
        el: '#file-import',
        data: {
            formData: {},
            fileImport: {},
            loading: false,
            readyToImport: false,
            messageFeedback: '',
            importIsReady: false,
            importedFormSections: [],
            acceptedFileTypes: ['xls', 'xlsx']
        },
        methods: {
            humanFileSize: function (size) {
                var i = Math.floor( Math.log(size) / Math.log(1024) );
                return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
            },
        
            buildFormData: function(evt) {
                this.messageFeedback = '';
                
                var file = evt.target.files[0];
                
                this.formData = new FormData();
                this.formData.append('file_import_attributes[file]', file);
                this.formData.append('file_import_attributes[import_type]', "form");
                
                if (_currentAccountId) {
                    this.formData.append('file_import_attributes[account_id]', _currentAccountId);
                }
                
                $('#file_input_text').val(file.name + ' (' + this.humanFileSize(file.size) + ')');
                this.readyToImport = true;
            },
            closeModal: function (justClose) {
                if (this.importedFormSections.length > 0) {
                    this.importIsReady = true;
                }
                
                if (justClose) {
                    this.messageFeedback = '';
                    $('#file-import').modal('hide');
                }
            },
            submitFileImport: function () {
                var that = this;

                that.messageFeedback = '';
                that.loading = true;

                this.$http.post('/file_imports.json', that.formData).then(function (response) {
                    that.fileImport = response.data;
                    that.formData = {};
                    that.loading = false;
                    
                    that.importedFormSections = that.fileImport.import_result.form_sections_attributes;
                    
                    if (that.importedFormSections.length == 0) {
                    that.readyToImport = false;
                    that.messageFeedback = "A planilha importada não possui campos válidos, por favor utilize outra.";
                    } else {
                    that.importIsReady = true;
                    $('#file-import').modal('hide');
                    }
                }, function (response) {
                    that.loading = false;
                    that.readyToImport = false;
                    that.messageFeedback = 'Ocorreu um erro interno no Servidor. Por favor informe à equipe suporte'
                });
            }
        },
        ready: function () {
        }
    });
}

export default importModal
