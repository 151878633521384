import React, { useCallback, useEffect, useState } from 'react';
import ExternalEvent from './ExternalEvent';

import { getCalendarWorks } from '../../api/works';
import { eventCSSClassByStatus } from '../shared/eventStyle';
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';

import { WorkEventArg } from '../types';

const loadButtonTheme = createTheme({
  palette: {
    info: {
      main: '#777777',
    },
  },
});

const UnplannedWorksList = () => {
  const [unplannedWorks, setUnplannedWorks] = useState<WorkEventArg[]>([]);
  const [loaded, setIsLoaded] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState<number>(0);

  const createUrlParams = () => {
    const urlParams = new URLSearchParams();

    urlParams.append('unplanned', 'true');
    urlParams.append('statuses[]', 'not_started');
    urlParams.append('statuses[]', 'started');

    return urlParams;
  };

  useEffect(() => {
    const fetchUnplannedWorks = async () => {
      const urlParams = createUrlParams();

      try {
        const jsonData = await getCalendarWorks({ urlParams });
        if (jsonData.meta.total_pages - jsonData.meta.current_page > 0) {
          setNextPage(jsonData.meta.current_page + 1);
        }
        setUnplannedWorks(
          jsonData.results.map((el: any) => ({
            id: el.id,
            title: el.title,
            allDay: false,
            url: `/works/${el.id}`,
            extendedProps: {
              status: el.status,
              originalStart: el['start_time'],
              originalEnd: el['end_time'],
            },
            classNames: [eventCSSClassByStatus(el.status), `work-${el.id}`],
          }))
        );
        setIsLoaded(true);
      } catch (error) {
        console.error('Error:', error);
        alert(error.message);
      }
    };

    fetchUnplannedWorks();
  }, []);

  const addMoreWorks = useCallback(async () => {
    setIsLoaded(false);
    const urlParams = createUrlParams();
    urlParams.append('page', nextPage.toString());

    try {
      const jsonData = await getCalendarWorks({ urlParams });
      if (jsonData.meta.total_pages - jsonData.meta.current_page === 0) {
        setNextPage(0);
      } else {
        setNextPage(jsonData.meta.current_page + 1);
      }

      setUnplannedWorks((prevWorks) => [
        ...prevWorks,
        ...jsonData.results.map((el: any) => ({
          id: el.id,
          title: el.title,
          allDay: false,
          url: `/works/${el.id}`,
          extendedProps: {
            status: el.status,
            originalStart: el['start_time'],
            originalEnd: el['end_time'],
          },
          classNames: [eventCSSClassByStatus(el.status), `work-${el.id}`],
        })),
      ]);

      setIsLoaded(true);
    } catch (error) {
      console.error('Error:', error);
      setIsLoaded(true);
      alert(error.message);
    }
  }, [nextPage]);

  return (
    <ThemeProvider theme={loadButtonTheme}>
      <div className="unplanned-works-list">
        <ul>
          {loaded && unplannedWorks.length === 0 && (
            <Typography
              sx={{
                color: '#777',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
              }}
            >
              Nenhuma atividade encontrada
            </Typography>
          )}
          {unplannedWorks.map((work: WorkEventArg) => (
            <ExternalEvent key={work.id} workEvent={work} />
          ))}
        </ul>
        {nextPage > 0 && (
          <Box
            sx={{
              display: 'flex',
              padding: '16px 8px',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={addMoreWorks}
              type="button"
              disabled={!loaded}
              color="info"
              sx={{
                textTransform: 'none',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
              }}
            >
              Carregar mais
            </Button>
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default UnplannedWorksList;
