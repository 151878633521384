import { createRoot } from 'react-dom/client';
import { AiAssistButton } from "./components/AiAssistButton"

const renderFormAi = () => {
  const el = document.getElementById('form-ai_assist')
  if (el !== null) {
    const root = createRoot(el)
    root.render(
      <AiAssistButton />
    )
  }
}

export default renderFormAi
