import React, { memo, useEffect, useRef } from 'react';
import { Draggable } from '@fullcalendar/interaction';
import { WorkEventArg } from '../types';
interface ExternalEventProps {
  workEvent: WorkEventArg;
}

const ExternalEvent: React.FC<ExternalEventProps> = memo(({ workEvent }) => {
  const eventEl = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const draggable = new Draggable(eventEl.current!, {
      eventData: () => ({ ...workEvent, create: true }),
    });

    return () => {
      draggable.destroy();
    };
  }, [workEvent]);

  const eventClassNames = () => {
    let eventClassName =
      'fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2 draggable-event';

    workEvent.classNames.forEach((className) => {
      eventClassName += ` ${className}`;
    });

    return eventClassName;
  };

  return (
    <li>
      <a
        ref={eventEl}
        className={eventClassNames()}
        title={workEvent.title || ''}
        style={{
          cursor: 'pointer',
        }}
        href={workEvent.url || ''}
        target="_blank"
      >
        <div className="fc-event-main">
          <div className="fc-daygrid-event-dot"></div>
          <div className="fc-event-title">{workEvent.title}</div>
        </div>
      </a>
    </li>
  );
});

export default ExternalEvent;
