import Vue from 'vue'
import importModal from './import_modal'
import { mixin } from 'vue-focus'

import './components/form_section_component'
import renderFormAi from './ai_assist';

$(document).on('ready', function() {
  var form = document.getElementById('form')
  if (form) {
    var VueFocus = require('vue-focus');
    var formVue = new Vue({
      el: '#form',
      mixins: [mixin],
      data: {
        form: {
          name: '',
          account_id: _currentAccountId,
          form_type: '',
          access_type: 'all_users_access',
          form_sections_attributes: [{
            name: 'Seção 1',
            position: 0,
            removed: false,
            hierarchy_level: 0,
            subsections_attributes: [],
            fields_attributes: [{
              name: '',
              position: 0,
              field_type: "",
              field_type_touched: false,
              mandatory: false,
              include_in_parts_price: false,
              include_in_services_price: false,
              removed: false,
              field_options_merged_names: '',
              field_options_attributes: [ { name: '', score: 0, score_enabled: false } ],
              field_categories_attributes: []
            }]
          }],
          removed_fields: []
        },
        fieldSuggestions: '',
        submitting: false,
        formWasChanged: false,
        editMode: false,
        loading: true,
        form_types: [
          { text: "Auditoria",        value: "auditing" },
          { text: "Inventário",       value: "inventory" },
          { text: "Laudo",            value: "report" },
          { text: "Pesquisa",         value: "survey" },
          { text: "Ordem de Serviço", value: "service_order" },
          { text: "Outro",            value: "other" }
        ],
        modalOptionsScoresVisible: false,
        modalPermissionLevelVisible: false,
        modalFieldCategoriesVisible: false,
        modalFormSectionWeightVisible: false,
        currentField: {},
        currentFormSection: {}
      },
      computed: {
        formIsInvalid: function () {
          if (!(this.form.name && this.form.form_type)) {
            return true;
          } else {
            for (var i = 0; i < this.$children.length; ++i) {
              var section = this.$children[i];
              if (section.formSectionsAreInvalid) return true; 
            }
            return false;
          }
        }
      },
      watch: {
        fieldSuggestions () {
          var parseSuggestions = JSON.parse(this.fieldSuggestions)
          this.applySuggestions(parseSuggestions)
        }
      },
      methods: {
        showOptionsScores: function(field) {
          this.currentField = field;
          this.modalOptionsScoresVisible = true;
        },
        showPermissionLevel: function(field) {
          this.currentField = field;
          this.modalPermissionLevelVisible = true;
        },
        showFieldCategories: function(field) {
          this.currentField = field;
          this.modalFieldCategoriesVisible = true;
        },
        showSectionWeight: function(formSection) {
          this.currentFormSection = formSection;
          this.modalFormSectionWeightVisible = true;
        },
        applySuggestions: function(suggestions) {
          var that = this;
          var importedFormSections = suggestions;

          if (importedFormSections.length > 0) {
            var lastPosition = 0;
            // Procura a última posição
            for (var i = 0; i < that.form.form_sections_attributes.length; i++) {
              var existentFormSection = that.form.form_sections_attributes.length[i];
              if (existentFormSection > lastPosition) {
                lastPosition = existentFormSection;
              }
            }
            var positionOffset = lastPosition;

            // Adiciona as sections importadas
            for (var i = 0; i < importedFormSections.length; i++) {
              var newFormSectionAttributes = importedFormSections[i];
              delete Object.assign(newFormSectionAttributes, {
                fields_attributes: newFormSectionAttributes.fields,
                position: i + positionOffset + 1,
                removed: false,
                subsections_attributes: []
              })['fields'];
              if (newFormSectionAttributes.fields_attributes.length > 0) {
                for (var j = 0; j < newFormSectionAttributes.fields_attributes.length; j++) {
                  Object.assign(newFormSectionAttributes.fields_attributes[j], {
                    removed: false
                  })
                  if (newFormSectionAttributes.fields_attributes[j].field_options)
                    var fieldOptions = newFormSectionAttributes.fields_attributes[j].field_options
                    if (fieldOptions) {
                      for (var k = 0; k < fieldOptions.length; k++) {
                        Object.assign(fieldOptions[k], {
                          score: 0, score_enabled: false
                        })
                      }
                      var field_options_merged_names = fieldOptions.map(function(field_option) {
                        return field_option.name;
                      }).join(', ');
                    }
                    delete Object.assign(newFormSectionAttributes.fields_attributes[j], {
                      field_options_attributes: newFormSectionAttributes.fields_attributes[j].field_options,
                      field_options_merged_names: field_options_merged_names
                    })['field_options']
                }
              }
              that.form.form_sections_attributes.push(newFormSectionAttributes);
            }
            that.validateNested();
          }
        },
        confirmUpdate: function() {
          var msg =
            "Como já existem atividades e dados preenchidos utilizando esse formulário, a alteração " +
            "pode impactar nos dados e na exportação. Tem certeza que deseja continuar?";
          return _hasWorks ? confirm(msg) : true;
        },
        validateNested: function () {
          this.$resetValidation();
          for (var i = 0; i < this.$children.length; ++i) { this.$children[i].forceValidation(); }
          this.$validate(true);
        },
        submitForm: function() {
          var that = this;
          window.onbeforeunload = null;
          this.validateNested();
          if (that.formIsInvalid) {
            return false;
          } else {
            that.submitting = true;
            that.formIsInvalid = false;
            if (!that.confirmUpdate()) { that.submitting = false; return false; }
            var method = (that.editMode ? 'PUT' : 'POST');
            var url = (that.editMode ? ('/forms/' + that.form.id) : '/forms') + '.json?form_section_version=true';
            var data = JSON.stringify({ form: that.form });
            $.ajax({
              method: method, data: data, url: url, dataType: 'json', contentType: 'application/json; charset=utf-8',
              success: function(response) { window.location.href = '/forms'; },
              error: function(response) {
                alert(response.responseJSON.error)
                console.log(response); 
                that.submitting = false;
              }
            });
          }
        },
        deleteForm: function() {
          var that = this;
          var confirmed = confirm("Tem certeza que deseja excluir?");
          if (!confirmed) return false;
          $.ajax({
            method: 'DELETE', url: '/forms/' + that.form.id + '.json',
            success: function(response) { 
              window.location.href = '/forms'; 
            },
            error: function(response) { 
              alert("Ocorreu um erro e o formulário não foi excluído.")
              console.log(response);           
            }
          });
        },
        openImportModal: function () {
          var that = this;
          
          importModal.fileImport.fields_attributes = [];
          importModal.importIsReady = false;
          importModal.importedFormSections = [];
          
          $('#file-import').modal('show');
          
          importModal.$watch('importIsReady', function () {
            if (importModal.importIsReady && importModal.importedFormSections.length > 0) {
              var lastPosition = 0;
              
              // Procura a última posição
              for (var i = 0; i < that.form.form_sections_attributes.length; i++) {
                var existentFormSection = that.form.form_sections_attributes.length[i];
                
                if (existentFormSection > lastPosition) {
                  lastPosition = existentFormSection;
                }
              }
              
              var positionOffset = lastPosition;
              
              // Adiciona as sections importadas
              for (var i = 0; i < importModal.importedFormSections.length; i++) {
                var newFormSectionAttributes = importModal.importedFormSections[i];
                newFormSectionAttributes.position += positionOffset;
                
                that.form.form_sections_attributes.push(newFormSectionAttributes);
              }
              
              that.validateNested();
              
              importModal.importIsReady = false;
              that.form.file_import_id = importModal.fileImport.id;

              $('#file-import').modal('hide');
            }
          });
        },
        sortBy: function(array, sortBy) { return array.sort(function(obj1, obj2) { return obj1[sortBy] - obj2[sortBy]; }); },
        removeAllFields: function () { for (var i = 0; i < this.fieldsAttributes.length; ++i){ this.removeField(i) } },
        formChangeHandler: function () {
          var that = this;
          $(document).on('change', 'select', function() {
            that.formWasChanged = true;
          });

          $(document).on('change keypress', 'input', function() {
            that.formWasChanged = true;
          });

          $(document).on('change keypress', 'textarea', function() {
            that.formWasChanged = true;
          });
        },
        beforeUnloadHandler: function () {
          var that = this;
          window.onbeforeunload = function() {
            if (that.formWasChanged) {
              return "Because it's Sunday, I'll be nice and let you know you forgot to save!";
            }
          };
        },
        
        allActiveFields: function() {
          var fields = [];
          this.allActiveFieldsInSessions(this.form.form_sections_attributes, fields);
        
          return fields;
        },
      
        allActiveFieldsInSessions: function(sections, fields) {
          for (var sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
            var formSection = sections[sectionIndex];
          
            if (formSection.removed) {
              continue;
            }
            
            for (var fieldIndex = 0; fieldIndex < formSection.fields_attributes.length; fieldIndex++) {
              var field = formSection.fields_attributes[fieldIndex];
            
              if (field.removed) {
                continue;
              }
              
              fields.push(field);
            }
            
            this.allActiveFieldsInSessions(formSection.subsections_attributes, fields);
          }
        }
      },
      
      // TODO: Ao atualizar para vuejs 2 trocar 'ready' por 'mounted'
      ready: function () {
        var that = this;
        that.formChangeHandler();
        that.beforeUnloadHandler();

        if (_form && _form.id) {
          that.editMode = true;
          that.form = _form;
          that.loading = false;
          that.$activateValidator();

        } else {
          that.$activateValidator();
          that.loading = false;
        }
      }
      
    });
    // TODO: Remove global variables on migrate from VueJS 1
    window._editingForm = formVue;
    renderFormAi()
  }
});
