import Vue from 'vue'

Vue.component('field-permission-component', {
  template: '#field_permission_component',
  props: {
    show: Boolean,
    currentField: Object
  },
  methods: {
    forceValidation: function() {
      //implementação vazia, apenas para atender parent ao salvar
      //TODO ajustar para que isso não seja mais necessário.
    },
    
    close: function() {
      this.show = false;
    }
  }
})
