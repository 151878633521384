import Vue from 'vue'

Vue.component('Modal', {
  template: '#modal-template',
  props: ['show', 'onClose'],
  methods: {
      forceValidation: function() {
        //implementação vazia, apenas para atender parent ao salvar
        //TODO ajustar para que isso não seja mais necessário.
      },
    close: function () {
        this.onClose();
    }
  },
  ready: function () {
    document.addEventListener("keydown", function(e) {
      if (this.show && e.keyCode == 27) {
        this.onClose();
      }
    });
  }
});