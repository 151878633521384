import Vue from 'vue'

Vue.component('form-section-weight-component', {
  template: '#form_section_weight_component',
  props: {
    show: Boolean,
    currentFormSection: Object
  },
  methods: {
    forceValidation: function() {
      //implementação vazia, apenas para atender parent ao salvar
      //TODO ajustar para que isso não seja mais necessário.
    },
    
    close: function() {
      this.show = false;
    }
  }
})