import React from 'react';
import UnplannedWorksList from './UnplannedWorksList';
import { Typography } from '@mui/material';

import { LeftSectionArg } from '../types';

interface CalendarLeftSectionProps {
  leftSection: LeftSectionArg;
}

const CalendarLeftSection: React.FC<CalendarLeftSectionProps> = ({
  leftSection,
}) => {
  return (
    <>
      {leftSection.leftSectionVisible && (
        <div className="fc left-section">
          <div className="fc-toolbar-chunk">
            <button
              type="button"
              title="A planejar"
              aria-pressed="false"
              className="fc-leftSectionButton-button fc-button fc-button-primary"
              onClick={leftSection.updateLeftSectionVisibility}
            >
              <span
                className="fc-icon fc-icon-calendar-material-symbol-left-button"
                role="img"
              />
            </button>
          </div>
          <Typography variant="h6" marginBottom={'8px'}>
            A planejar
          </Typography>
          <Typography marginBottom={'8px'}>Atividades sem data:</Typography>
          <UnplannedWorksList />
        </div>
      )}
    </>
  );
};

export default CalendarLeftSection;
