import React, { useCallback, useState, RefObject } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import {
  EventDropArg,
  EventApi,
  EventClickArg,
  EventMountArg,
  EventHoveringArg,
  EventContentArg,
} from '@fullcalendar/core/index.js';
import { EventReceiveArg } from '@fullcalendar/interaction';

import { getCalendarWorks, updateWork } from '../../api/works';
import {
  eventCSSClassByStatus,
  eventEditable,
  lateEvent,
} from '../shared/eventStyle';
import EditWorkDuration from './EditWorkDuration';
import { EditWorkDurationArg, LeftSectionArg } from '../types';

interface WorksFullCalendarProps {
  calendarRef: RefObject<any>;
  userTimeZone: string;
  userCanUpdateWork: boolean;
  leftSection: LeftSectionArg;
}

const WorksFullCalendar: React.FC<WorksFullCalendarProps> = ({
  calendarRef,
  userTimeZone,
  userCanUpdateWork,
  leftSection,
}) => {
  const baseEditWorkObject: EditWorkDurationArg = {
    calendarInfo: null,
    hasEndTime: false,
    oldEventStart: null,
    oldEventEnd: null,
    userTimeZone: userTimeZone,
    successCallback: () => {},
    failureCallback: () => {},
  };

  const [editWorkObject, setEditWorkObject] = useState({
    ...baseEditWorkObject,
  });

  const fetchEvents = useCallback(async (info) => {
    const startDate = info.start.toLocaleDateString('pt-BR');
    const endDate = info.end.toLocaleDateString('pt-BR');

    const urlParams = new URLSearchParams(window.location.search);

    urlParams.append(
      'calendar_scheduled_range_time',
      `${startDate} - ${endDate}`
    );
    urlParams.append('without_pagination', 'true');

    const jsonData = await getCalendarWorks({ urlParams: urlParams }).catch(
      (e) => {
        alert(e.message);
        return [];
      }
    );

    return jsonData.map((el) => {
      return {
        id: el.id,
        title: el.title,
        start: el['start_time'],
        end: el['end_time'],
        url: `/works/${el.id}`,
        extendedProps: {
          status: el.status,
        },
        editable: userCanUpdateWork && eventEditable(el.status),
        classNames: [eventCSSClassByStatus(el.status), `work-${el.id}`],
      };
    });
  }, []);

  const createWorkUpdateBody = (workEvent: EventApi) => {
    const startTime = workEvent.startStr;
    let endTime: string | null = null;

    if (workEvent.end) {
      endTime = workEvent.endStr;
    }

    return {
      work: {
        start_time: startTime,
        end_time: endTime,
      },
    };
  };

  const eventClassNames = (arg: EventContentArg) => {
    const { isStart, event, view } = arg;
    const { id, extendedProps, endStr } = event;
    const status = extendedProps['status'];
    const classNames = [eventCSSClassByStatus(status), `work-${id}`];

    if (lateEvent(status, endStr)) {
      classNames.push('late-event');
    }

    if (view.type !== 'timeGridDay' && !isStart) {
      classNames.push('repeated-event');
    }

    return classNames;
  };

  const eventClick = (info: EventClickArg) => {
    info.jsEvent.preventDefault(); // don't let the browser navigate

    if (info.event.url) {
      window.open(info.event.url);
    }
  };

  const eventResize = (info: EventResizeDoneArg) => {
    const body = createWorkUpdateBody(info.event);

    updateWork({ id: info.event.id, body: body }).catch((e) => {
      info.revert();
      alert(e.message);
    });
  };

  const eventDrop = (info: EventDropArg) => {
    const {
      type: viewType,
      currentStart,
      currentEnd,
    } = calendarRef.current.getApi().view;

    const currentStartNumber = currentStart.getTime();
    const currentEndNumber = currentEnd.getTime();
    const eventStartNumber = info.event.start?.getTime() || 0;
    const eventEndNumber = info.event.end?.getTime() || 0;

    if (
      viewType === 'timeGridDay' &&
      eventStartNumber >= currentStartNumber &&
      eventEndNumber <= currentEndNumber
    ) {
      const body = createWorkUpdateBody(info.event);
      updateWork({ id: info.event.id, body: body }).catch((e) => {
        info.revert();
        alert(e.message);
      });

      return;
    }

    setEditWorkObject({
      calendarInfo: info,
      hasEndTime: true,
      oldEventStart: info.oldEvent.startStr,
      oldEventEnd: info.oldEvent.endStr,
      userTimeZone: userTimeZone,
      successCallback: (newStart, newEnd) => {
        const event = info.event;

        const newEvent = {
          id: event.id,
          title: event.title,
          start: newStart,
          end: newEnd,
          allDay: false,
          url: event.url,
          extendedProps: { ...event.extendedProps },
          classNames: [...event.classNames],
        };

        calendarRef.current.getApi().addEvent(newEvent, true);
        info.event.remove();

        setEditWorkObject({ ...baseEditWorkObject });
      },
      failureCallback: () => {
        info.revert();
        setEditWorkObject({ ...baseEditWorkObject });
      },
    });
  };

  const eventReceive = (info: EventReceiveArg) => {
    const event = info.event;
    let hasEndTime = false;

    const viewType = calendarRef.current.getApi().view.type;

    if (viewType === 'timeGridDay') {
      hasEndTime = true;
    }

    setEditWorkObject({
      calendarInfo: info,
      hasEndTime: hasEndTime,
      oldEventStart: event.extendedProps['originalStart'],
      oldEventEnd: event.extendedProps['originalEnd'],
      userTimeZone: userTimeZone,
      successCallback: (newStart, newEnd) => {
        const status = event.extendedProps['status'];

        const newEvent = {
          id: event.id,
          title: event.title,
          start: newStart,
          end: newEnd,
          allDay: false,
          url: event.url,
          extendedProps: {
            status: status,
          },
          classNames: [...event.classNames],
        };

        (info.draggedEl.parentNode as HTMLElement).style.display = 'none';

        calendarRef.current.getApi().addEvent(newEvent, true);
        info.event.remove();
        setEditWorkObject({ ...baseEditWorkObject });
      },
      failureCallback: () => {
        info.revert();
        setEditWorkObject({ ...baseEditWorkObject });
      },
    });
  };

  const eventDidMount = (info: EventMountArg) => {
    info.el.title = info.event.title;
  };

  const eventMouseEnter = (info: EventHoveringArg) => {
    const eventId = info.event.id;
    document.querySelectorAll(`.work-${eventId}`).forEach((el) => {
      el.classList.add('hover');
    });
  };

  const eventMouseLeave = (info: EventHoveringArg) => {
    const eventId = info.event.id;
    document.querySelectorAll(`.work-${eventId}`).forEach((el) => {
      el.classList.remove('hover');
    });
  };

  const defineInitialSettings = () => {
    const formatDateString = (date: string) => {
      // DD/MM/YYYY to YYYY-MM-DD
      const dateArr = date.split('/');
      return [dateArr[2], dateArr[1], dateArr[0]].join('-');
    };

    const dateDifferenceInDays = (startDate: number, endDate: number) =>
      (endDate - startDate) / 86_400_000;

    const urlParams = new URLSearchParams(window.location.search);
    const schedueldRangeTimeParam = urlParams.get(
      'calendar_scheduled_range_time'
    );

    let initialDate: string | undefined = undefined;
    let initialView = 'dayGridMonth';
    let leftHeaderToolbar = 'prev,next today';

    if (schedueldRangeTimeParam) {
      const rangeDate = schedueldRangeTimeParam.split('-');

      const startDate = formatDateString(rangeDate[0].trim());
      const endDate = formatDateString(rangeDate[1].trim());
      const differenceDays = dateDifferenceInDays(
        Date.parse(startDate),
        Date.parse(endDate)
      );

      initialDate = startDate;

      if (differenceDays === 0) {
        initialView = 'timeGridDay';
      }
    }

    if (userCanUpdateWork && !leftSection.leftSectionVisible) {
      leftHeaderToolbar = 'leftSectionButton prev,next today';
    }

    return {
      initialView: initialView,
      initialDate: initialDate,
      leftHeaderToolbar: leftHeaderToolbar,
    };
  };

  const initialSettings = defineInitialSettings();

  return (
    <div className="calendar">
      <FullCalendar
        ref={calendarRef}
        timeZone={userTimeZone}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={initialSettings.initialView}
        initialDate={initialSettings.initialDate}
        eventTimeFormat={{
          // like '14:00'
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
        }}
        views={{
          timeGridDay: {
            eventMaxStack: 6,
            slotEventOverlap: false,
          },
        }}
        headerToolbar={{
          left: initialSettings.leftHeaderToolbar,
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,timeGridDay',
        }}
        customButtons={{
          leftSectionButton: {
            text: 'A planejar',
            icon: 'calendar-material-symbol-left-button',
            click: () => {
              leftSection.updateLeftSectionVisibility();
            },
          },
        }}
        selectable={true}
        editable={userCanUpdateWork}
        allDaySlot={false}
        dayMaxEventRows={true}
        navLinks={true}
        defaultAllDay={false}
        forceEventDuration={true}
        locale={ptBrLocale}
        events={fetchEvents}
        eventClassNames={eventClassNames}
        eventClick={eventClick}
        eventDrop={eventDrop}
        eventResize={eventResize}
        eventReceive={eventReceive}
        eventDidMount={eventDidMount}
        eventMouseEnter={eventMouseEnter}
        eventMouseLeave={eventMouseLeave}
      />

      <EditWorkDuration editWorkObject={editWorkObject} />
    </div>
  );
};

export default WorksFullCalendar;
