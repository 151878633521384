import Vue from 'vue'
import { mixin } from 'vue-focus'
import VueValidator from 'vue-validator'

import '../vue_directives/selectize'
import './form_section_weight_component'
import './field_categories_component'
import './field_options_scores_component'
import './modal'
import './field_permission_component'

var lastPosition = 0;
var planAccess = checkCurrentCode(PRODUTIVIDADE());

Vue.use(VueValidator)
Vue.component('field-component', {
  mixins: [mixin],
  validators: {
    uniquenessFieldName: {
      check: function(value, index) {
        var fields = this._vm.$root.allActiveFields();
        
        var fieldNames = fields.map(function(field) { 
          if (field.name.length > 0) {
            return field.name; 
          }
        });
        
        this._vm.$data.repeatedNames = fieldNames.getDuplicates();
        
        return jQuery.isEmptyObject(this._vm.$data.repeatedNames);
      }
    }
  },
  props: {
    fieldsAttributes: Array,
    formSections: Array,
    previousActiveFormSectionIndex: Number,
    formSectionHierarchyLevel: Number,
    currentFormSection: Object,
    nextActiveFormSectionIndex: Number,
    showOptionsScores: Function,
    showPermissionLevel: Function,
    showFieldCategories: Function,
    parentIdentifier: String
  },
  template: "#field-template",
  computed: {
    fieldsAreInvalid: function () {
      this.$resetValidation();
      var hasRepeatedNames;
      for (var i = 0; i < this.fieldsAttributes.length; ++i){
        if (this.repeatedNames.hasOwnProperty(this.fieldsAttributes[i].name)) {
          hasRepeatedNames = true;
          break
        }
      }
      return this.$validation.invalid || hasRepeatedNames;
    }
  },
  data: function() {
    return {
      field_types: [
        { text: 'Texto',                 value: 'text',           description: 'Campo de resposta aberta para preencher com um texto.', disabled: false },
        { text: planAccess ? 'Questão de Checklist' : 'Questão de Checklist (plano Produtividade)',  value: 'checklist_item', description: 'Questão com opções para indicar se item está conforme ou não, podendo adicionar observações e anexar evidências fotográficas.', disabled: !planAccess },
        { text: 'Lista de Peças e Materiais',        value: 'parts_select',  description: 'Campo para selecionar uma ou mais peças ou materiais que estão cadastradas.', disabled: false },
        { text: 'Lista de Serviços',     value: 'services_select',  description: 'Campo para selecionar um ou mais serviços realizados que estão cadastrados.', disabled: false },
        { text: 'Número',                value: 'number',         description: 'Campo para resposta com número.', disabled: false },
        { text: 'Data e Hora',           value: 'datetime',       description: 'Campo para resposta com data e hora.', disabled: false },
        { text: 'Opções para seleção única',         value: 'single_select',  description: 'Questão com opções de respostas pré-definidas, permitindo selecionar apenas uma delas.', disabled: false },
        { text: 'Opções para seleção múltipla',      value: 'multi_select',   description: 'Questão com opções de respostas pré-definidas, permitindo selecionar várias delas.', disabled: false },
        { text: 'Fotos',                 value: 'image',          description: 'Campo para anexar fotos da câmera, tendo a opção de escrever uma legenda.', disabled: false },
        { text: 'Localização GPS',       value: 'location',       description: 'Campo para salvar as coordenadas geográficas e o endereço atual do dispositivo.', disabled: false },
        { text: 'Assinatura',            value: 'signature',      description: 'Campo para assinatura digital na tela de dispositivo.', disabled: false },
        { text: 'Texto grande',          value: 'text_area',      description: 'Campo de resposta aberta para preencher com textos maiores e com várias linhas, como comentários e observações.', disabled: false },
        { text: 'QRCode / Código de barras',  value: 'barcode',  description: 'Campo para leitura automática de QRCode ou Código de barras utilizando a câmera.', disabled: false },
        { text: 'Data',                  value: 'date',           description: 'Campo para resposta somente com data.', disabled: false },
        { text: 'Hora',                  value: 'time',           description: 'Campo para resposta somente com hora.', disabled: false }
      ],
      code_types: [
        { value: null, text: 'Identificar tipo de código automaticamente.'},
        { value: 'upca', text: 'UPC-A' },
        { value: 'upce', text: 'UPC-E' },
        { value: 'ean8', text: 'EAN-8' },
        { value: 'ean13', text: 'EAN-13' },
        { value: 'rss14', text: 'RSS-14' },
        { value: 'codabar', text: 'CODABAR' },
        { value: 'code39', text: 'CODE 39' },
        { value: 'code93', text: 'CODE 93' },
        { value: 'code128', text: 'CODE 128' },
        { value: 'itf', text: 'ITF' },
        { value: 'qrcode', text: 'QRCode' },
        { value: 'pdf417', text: 'PDF417' },
        { value: 'databarmatrix', text: 'DataBar MATRIX' },
      ],
      repeatedNames: {},
      fieldTypesWithCodeTypes: ['barcode'],
      fieldTypesWithNoRepeat: ['barcode', 'checklist_item', 'single_select', 'text', 'number', 'date', 'time', 'datetime'],
      fieldTypesWithOptions: ['single_select', 'multi_select', 'checklist_item'],
      fieldTypesWithScoresAndAlerts: ['single_select', 'checklist_item'],
      fieldTypesWithPartPrice: ['number', 'parts_select'],
      fieldTypesWithServicePrice: ['number', 'services_select'],
      focused: '',
      showRequiredMessage: false
    }
  },
  methods: {
    setDefaultFieldOptions: function (field) {
      if (field.field_options_merged_names != '') {
        return;
      }
      
      if (field.field_type == 'checklist_item') {
        var options = [];
        
        for (var i = 0; i < _defaultChecklistItemOptions.length; i++) {
          var defaultOption = _defaultChecklistItemOptions[i];
          options.push({
            name: defaultOption.name,
            score: defaultOption.score,
            score_enabled: defaultOption.score_enabled,
            alert_enabled: defaultOption.alert_enabled
          });
        }
        
        field.field_options_attributes = options;
        
        field.field_options_merged_names = field.field_options_attributes.map(function(field_option) {
          return field_option.name;
        }).join(', ');
        
        this.optionsChanged(field);
      }
    },
    revalidateAll: function () {
      this.$parent.forceValidation()
    },
    addField: function(index, newField, fieldsAttributes) {
      var that = this;
      var fields = fieldsAttributes || this.fieldsAttributes;
      lastPosition = this.lastFieldPosition(fields);
      if (index == null) index = ++lastPosition;
      if (!newField) {
        newField = {
          name: '', 
          position: index, 
          field_type: '', 
          removed: false, 
          mandatory: false, 
          include_in_parts_price: false,
          include_in_services_price: false,
          field_type_touched: false, 
          field_options_merged_names: '',
          field_options_attributes: [ { name: '', score: 0 } ],
          field_categories_attributes: []
        };
      }
      this.focused = fieldsAttributes ? this.focused : index;
      fields.splice(index, 0, newField);
      this.shiftFields(index + 1, 'right', fields);

      this.upgradeMdlDom();
    },
    activeFields: function(fieldsAttributes) {
      var fields = fieldsAttributes || this.fieldsAttributes;
      return fields.filter(function(field) {
        return field.removed == false;
      });
    },
    firstFieldPosition: function(fieldsAttributes) {
      var firstFieldPosition;
      var fields = fieldsAttributes || this.fieldsAttributes;
      if (fields.length) {
        var activeFields = this.activeFields(fields);
        firstFieldPosition = Math.min.apply(Math, activeFields.map(function(field) {
          return field.position;
        }));
      } else {
        firstFieldPosition = 0;
      }
      return firstFieldPosition;
    },
    lastFieldPosition: function(fieldsAttributes) {
      var lastFieldPosition;
      var fields = fieldsAttributes || this.fieldsAttributes;
      if (fields.length) {
        var activeFields = this.activeFields(fields);
        lastFieldPosition = Math.max.apply(Math, activeFields.map(function(field) {
          return field.position;
        }));
      } else {
        lastFieldPosition = 0;
      }
      return lastFieldPosition;
    },
    nextActiveFieldIndex: function (index) {
      var length = this.fieldsAttributes.length;
      for (var i = index + 1; i < length; ++i) { if (this.fieldsAttributes[i].removed == false) { return i; } }
      return -1;
    },
    previousActiveFieldIndex: function (index) {
      for (var i = index - 1; i >= 0; --i) { if (this.fieldsAttributes[i].removed == false) { return i; } }
      return -1;
    },
    insertIntoPreviousActiveFormSection: function (index) {
      console.log('inserting in next active section');
      var fieldToUp = this.fieldsAttributes[index];
      var previousActiveSection = this.formSections[this.previousActiveFormSectionIndex];
      fieldToUp.position = this.lastFieldPosition(previousActiveSection.fields_attributes) + 1;
      var newField = this.copyObject(fieldToUp);
      this.addField(previousActiveSection.fields_attributes.length, newField, previousActiveSection.fields_attributes);
      this.removeField(index);
    },
    insertIntoNextActiveFormSection: function (index) {
      console.log('inserting in next active section');
      //if (this.$parent.$children[this.nextActiveFormSectionIndex])
      //  this.$parent.$children[this.nextActiveFormSectionIndex].focused = 0;
      var fieldToDown = this.fieldsAttributes[index];
      var nextActiveSection = this.formSections[this.nextActiveFormSectionIndex];
      fieldToDown.position = this.firstFieldPosition(nextActiveSection.fields_attributes);
      var newField = this.copyObject(fieldToDown);
      this.addField(0, newField, nextActiveSection.fields_attributes);
      this.removeField(index);
    },
    upFieldPosition: function(index) {
      console.log('up position');
      var fieldToUp = this.fieldsAttributes[index];
      if (fieldToUp.position == this.firstFieldPosition() && this.previousActiveFormSectionIndex >= 0) {
        this.insertIntoPreviousActiveFormSection(index);
      } else if (index > 0) {
        var previousFieldIndex = this.previousActiveFieldIndex(index);
        if (previousFieldIndex >= 0) {
          this.focused = previousFieldIndex;
          var fieldAbove = this.fieldsAttributes[this.focused];
          var positionA = fieldToUp.position;
          fieldToUp.position = fieldAbove.position;
          fieldAbove.position = positionA;
          this.fieldsAttributes = this.sortBy(this.fieldsAttributes, 'position');
        }
      }
    },
    downFieldPosition: function(index) {
      console.log('down position');
      var fieldToDown = this.fieldsAttributes[index];
      if (fieldToDown.position == this.lastFieldPosition() && this.nextActiveFormSectionIndex >= 0) {
        this.insertIntoNextActiveFormSection(index);
      } else if (index < (this.fieldsAttributes.length - 1)) {
        var nextFieldIndex = this.nextActiveFieldIndex(index);
        if (nextFieldIndex >= 0) {
          this.focused = nextFieldIndex;
          var fieldBelow = this.fieldsAttributes[this.focused];
          var positionA = fieldToDown.position;
          fieldToDown.position = fieldBelow.position;
          fieldBelow.position = positionA;
          this.fieldsAttributes = this.sortBy(this.fieldsAttributes, 'position');
        }
      }
    },
    removeField: function(index, mustConfirm) {
      var confirmed = mustConfirm ? confirm("Tem certeza que deseja remover este campo?") : true;
      if(confirmed) {
        if (this.fieldsAttributes[index].id) {
          console.log('Setting field (' + this.fieldsAttributes[index].name + ') to removed');
          this.fieldsAttributes[index].name = 'removed-' + this.fieldsAttributes[index].name;
          this.fieldsAttributes[index].removed = true;
          this.fieldsAttributes[index].position = -1;
          this.shiftFields(index + 1, 'left');
        } else {
          this.fieldsAttributes[index].removed = true;
          this.fieldsAttributes.splice(index, 1);
          this.shiftFields(index, 'left');
        }
      }
    },
    optionsChanged: function(field) {
      var optionsNames = field.field_options_merged_names.split(',');
      var previousOptions = field.field_options_attributes;
      var removedOptions = field.field_options_attributes.slice();
      var currentOptions = []; 
      
      /* Atualiza a lista das opções padrão */
      for (var i = 0; i < optionsNames.length; i++) {
        var optionName = optionsNames[i].trim();
        
        var option = null;
        
        for (var j = 0; j < previousOptions.length; j++) {
          var item = previousOptions[j];
          
          if (item.name === optionName) {
            option = item;
            break;
          }
        }
        
        var isNewOption = (option == null);
        
        if (isNewOption) {
          var defaultScoreEnabled = (field.field_type == 'checklist_item');
          
          option = {
            name: optionName,
            score: 0,
            score_enabled: defaultScoreEnabled
          };
        } else {
          /* Se ainda está na lista, então não foi removido */
          option._destroy = false;
          removedOptions.splice(removedOptions.indexOf(option), 1);
        }
        
        currentOptions.push(option);
      }
      
      /* Mantém as opções removidas com "_destroy = true" para API entender
         que deve remover essa opção do banco de dados */
      for (var i = 0; i < removedOptions.length; i++) {
        var removedOption = removedOptions[i];
        
        var persisted = (removedOption.id != null);
        
        if (persisted) {
          removedOption._destroy = true;
          currentOptions.push(removedOption);
        }
      }
      
      field.field_options_attributes = currentOptions;
    },
    duplicateField: function(index) {
      console.log('duplicando: ', index)
      var newField = this.copyObject(this.fieldsAttributes[index]);
      newField.position++;
      this.addField(index + 1, newField);
    },
    shiftFields: function(from, direction, fieldsAttributes) {
      //console.log('shifting fields to ' + direction);
      var fields = fieldsAttributes || this.fieldsAttributes;
      var i;
      if (direction == 'left') {
        console.log('------------------------------------');
        for (i = from; i < fields.length; ++i) {
          if (fields[i].removed == false) {
            fields[i].position = fields[i].position - 1;
          }
        }
        console.log('------------------------------------');
      } else if (direction == 'right') {
        for (i = from; i < fields.length; ++i) {
          if (fields[i].removed == false) {
            fields[i].position = fields[i].position + 1;
          }
        }
      }
    },
    // --------- AUX functions ---------
    forceValidation: function () { if (this.$validation.invalid) { this.showRequiredMessage = true; } },
    fixPositions: function() {
      var position = 0;
      for (var i = 0; i < this.fieldsAttributes.length; ++i) {
        if (this.fieldsAttributes[i].removed == true ) { this.fieldsAttributes[i].position = -1; }
        else { this.fieldsAttributes[i].position = position++; }
      }
    },
    sortBy: function(array, sortBy) { return array.sort(function(obj1, obj2) { return obj1[sortBy] - obj2[sortBy]; }); },
    
    // Workaround for issue: https://github.com/vuejs/vue-validator/issues/173
    identifierFor: function(prefix, index) { 
      return prefix + "_" + (this.parentIdentifier ? this.parentIdentifier : "") + "_" + index;
    },
    
    // Returns a deep copy of object without property id
    copyObject: function(object) {
      return JSON.parse( JSON.stringify(object, function(key, value) { return (key == 'id') ? undefined : value; } ) );
    },
    upgradeMdlDom: function () {
      /* necessario para atualizar material design lite */
      this.$nextTick(function () {
        componentHandler.upgradeDom();
      })
    }
  },
  ready: function() {
    this.upgradeMdlDom();
    this.$activateValidator();
    lastPosition = this.lastFieldPosition();
    this.fixPositions();
  }
});

Vue.component('form-section-component', {
  mixins: [mixin],
  validators: {
    uniquenessFormSectionName: {
      check: function (value, index) {
        var formSections = this._vm.activeFormSections;
        var formSectionNames = formSections.map(function(formSection) { if (formSection.name.length > 0) return formSection.name; });
        this._vm.$data.repeatedNames = formSectionNames.getDuplicates();
        return jQuery.isEmptyObject(this._vm.$data.repeatedNames);
      }
    }
  },
  props: {
    formSectionsAttributes: Array,
    showSectionWeight: Function,
    showOptionsScores: Function,
    showPermissionLevel: Function,
    showFieldCategories: Function,
    parentEventBus: Object,
    parentIndex: Number,
    parentHierarchyLevel: Number,
    parentIdentifier: String
  },
  template: "#form-section-template",
  data: function() {
    return {
      repeatedNames: {},
      focused: '',
      showRequiredMessage: false,
      subsectionsEventBus: new Vue()
    }
  },
  computed: {
    activeFormSections: function() {
      return this.formSectionsAttributes.filter(function(formSection) {
        return formSection.removed == false;
      });
    },
    formSectionsAreInvalid: function() {
      this.$resetValidation();
      for (var i = 0; i < this.formSectionsAttributes.length; ++i){
        if (this.repeatedNames.hasOwnProperty(this.formSectionsAttributes[i].name)) return true;
        for (var j = 0; j < this.$children.length; ++j){
          if ( this.$children[j].fieldsAreInvalid ) return true;
        }
      }
      return false;
    }
  },
  methods: {
    addFormSection: function(index, newFormSection, position, hierarchyLevel) {
      var lastPosition = (position >= 0) ? position : this.lastFormSectionPosition();
      if (index == null) index = lastPosition + 1;
      if (!newFormSection) {
        newFormSection = {
          name: '',
          position: lastPosition + 1,
          removed: false,
          hierarchy_level: hierarchyLevel,
          subsections_attributes: [],
          fields_attributes: [ {
            name: '',
            position: 0,
            field_type: "",
            mandatory: false,
            removed: false,
            field_options_merged_names: '',
            field_options_attributes: [ { name: '', score: 0, score_enabled: false } ],
            field_categories_attributes: []
          }]
        };
      }
      this.focused = index;
      this.formSectionsAttributes.splice(index, 0, newFormSection);
      this.shiftFormSections(index + 1, 'right');
      this.forceValidation();
    },
    addSubsection: function(parentIndex, parentHierarchyLevel) {
      this.subsectionsEventBus.$emit('addFormSectionRequestedFromParent', parentIndex, parentHierarchyLevel);
    },
    addFormSectionRequestedFromParent: function(parentIndex, parentHierarchyLevel) {
      if (parentIndex != this.parentIndex) {
        return;
      }
      
      if (parentHierarchyLevel != this.parentHierarchyLevel) {
        return;
      }
      
      this.addFormSection(null, null, -1, parentHierarchyLevel + 1);
    },
    allActiveFields: function() {
      var allFields = [];
      for (var section_index = 0; section_index < this.formSectionsAttributes.length; ++section_index) {
        if (this.formSectionsAttributes[section_index].removed == true) continue;
        var fields = this.formSectionsAttributes[section_index].fields_attributes;
        for (var field_index = 0; field_index < fields.length; ++field_index){
          var field = fields[field_index];
          if (field.removed == false) allFields.push(field);
        }
      }
      return allFields;
    },
    firstFormSectionPosition: function() {
      var firstFormSectionPosition;
      var activeFormSections = this.activeFormSections;
      if (activeFormSections.length) {
        firstFormSectionPosition = Math.min.apply(Math, activeFormSections.map(function(formSection) {
          return formSection.position;
        }));
      } else {
        firstFormSectionPosition = 0;
      }
      return firstFormSectionPosition;
    },
    lastFormSectionPosition: function() {
      var lastFormSectionPosition;
      var activeFormSections = this.activeFormSections;
      if (activeFormSections.length) {
        lastFormSectionPosition = Math.max.apply(Math, activeFormSections.map(function(formSection) {
          return formSection.position;
        }));
      } else {
        lastFormSectionPosition = 0;
      }
      return lastFormSectionPosition;
    },
    upFormSectionPosition: function(index) {
      if (index > 0) {
        console.log('up formSection position');
        var formSectionToUp = this.formSectionsAttributes[index];
        var previousSectionIndex = this.previousActiveFormSectionIndex(index);
        if (previousSectionIndex >= 0) {
          var formSectionAbove = this.formSectionsAttributes[previousSectionIndex];
          var positionA = formSectionToUp.position;
          formSectionToUp.position = formSectionAbove.position;
          formSectionAbove.position = positionA;
          this.formSectionsAttributes = this.sortBy(this.formSectionsAttributes, 'position');
          this.focused = previousSectionIndex;
        }
      } else if (index == 0) {
        this.focused = index;
      }
    },
    downFormSectionPosition: function(index) {
      if (index < (this.formSectionsAttributes.length - 1)) {
        console.log('down formSection position');
        var formSectionToDown = this.formSectionsAttributes[index];
        var nextSectionIndex = this.nextActiveFormSectionIndex(index);
        if (nextSectionIndex >= 0) {
          var formSectionBelow = this.formSectionsAttributes[nextSectionIndex];
          var positionA = formSectionToDown.position;
          formSectionToDown.position = formSectionBelow.position;
          formSectionBelow.position = positionA;
          this.formSectionsAttributes = this.sortBy(this.formSectionsAttributes, 'position');
          this.focused = nextSectionIndex;
        }
      }
    },
    removeAllFieldsOfSection: function (index) {
      var section = this.formSectionsAttributes[index];
      for (var i = 0; i< section.fields_attributes.length; ++i){
        console.log('Setting field (' + section.fields_attributes[i].name + ') to removed');
        section.fields_attributes[i].name = 'removed-' + section.fields_attributes[i].name;
        section.fields_attributes[i].removed = true;
        section.fields_attributes[i].position = -1;
      }
    },
    removeFormSection: function(index) {
      if(confirm("Tem certeza que deseja remover esta seção?")) {
        if (this.formSectionsAttributes[index].id) {
          console.log('Setting formSection (' + this.formSectionsAttributes[index].name + ') to removed');
          this.formSectionsAttributes[index].name = 'removed-' + this.formSectionsAttributes[index].name;
          this.formSectionsAttributes[index].removed = true;
          this.formSectionsAttributes[index].position = -1;
          this.removeAllFieldsOfSection(index);
          this.shiftFormSections(index + 1, 'left');
        } else {
          this.formSectionsAttributes[index].removed = true;
          this.formSectionsAttributes.splice(index, 1);
          this.shiftFormSections(index, 'left');
        }
        this.formSectionsAttributes = this.sortBy(this.formSectionsAttributes, 'position');
        this.forceValidation();
      }
    },
    duplicateFormSection: function(index) {
      var newFormSection = this.copyObject(this.formSectionsAttributes[index]);
      newFormSection.position++;
      this.addFormSection(index + 1, newFormSection, this.formSectionsAttributes[index].hierarchy_level);
    },
    shiftFormSections: function(from, direction) {
      var i;
      var sections = this.formSectionsAttributes;
      if (direction == 'left') {
        for (i = from; i < sections.length; ++i) { if (sections[i].removed == false) { sections[i].position--; } }
      } else if (direction == 'right') {
        for (i = from; i < sections.length; ++i) { if (sections[i].removed == false) { sections[i].position++; } }
      }
    },
    findFormSectionBy: function(attr, value) {
      return this.formSectionsAttributes.find(function(formSection) { return formSection[attr] === value; })
    },
    previousActiveFormSectionIndex: function (index) {
      for (var i = (index - 1); i >= 0; --i) { if (this.formSectionsAttributes[i].removed == false) { return i; } }
      return -1;
    },
    nextActiveFormSectionIndex: function (index) {
      var length = this.formSectionsAttributes.length;
      for (var i = (index + 1); i < length; ++i) { if (this.formSectionsAttributes[i].removed == false) { return i; } }
      return -1;
    },
    // --------- AUX functions ---------
    validateNested: function() {
      for (var i = 0; i < this.$children.length; ++i) { this.$children[i].$resetValidation();  this.$children[i].forceValidation();}
    },
    forceValidation: function() {
      this.$resetValidation();
      if (this.$validation.invalid) { this.showRequiredMessage = true; }
      this.validateNested();
    },
    fixPositions: function() {
      var position = 0;
      for (var i = 0; i < this.formSectionsAttributes.length; ++i) {
        if (this.formSectionsAttributes[i].removed == true ) { this.formSectionsAttributes[i].position = -1; }
        else { this.formSectionsAttributes[i].position = position++; }
      }
    },
    removeAllFormSections: function() { 
      for (var i = 0; i < this.formSectionsAttributes.length; ++i) { this.removeFormSection(i) }
    },
    sortBy: function(array, sortBy) { return array.sort(function(obj1, obj2) { return obj1[sortBy] - obj2[sortBy]; }); },
    // Workaround for issue: https://github.com/vuejs/vue-validator/issues/173
    concatIndex: function(string, hierarchyLevel, index) { return string + "-" + hierarchyLevel + "-" + index; },
    
    // Workaround for issue: https://github.com/vuejs/vue-validator/issues/173
    identifierFor: function(prefix, index) {
      return prefix + "_" + (this.parentIdentifier ? this.parentIdentifier : "") + "_" + index;
    },
    
    // Returns a deep copy of object without property id
    copyObject: function(object) {
      return JSON.parse( JSON.stringify(object, function(key, value) { return (key == 'id') ? undefined : value; } ) );
    }
  },
  ready: function() {
    this.$activateValidator();
    this.fixPositions();
    lastPosition = this.lastFormSectionPosition();
    
    if (this.parentEventBus) {
      this.parentEventBus.$on('addFormSectionRequestedFromParent', this.addFormSectionRequestedFromParent);
    }
  }
});