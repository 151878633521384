import React from 'react';
import { Box, Icon, List, ListItem, ListItemText } from '@mui/material';

const CalendarFooter = () => {
  return (
    <List
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <ListItem sx={{ width: 'auto', padding: '8px' }}>
        <Icon
          className="material-symbols-outlined"
          sx={{ fontSize: '16px', color: 'var(--not-started-work)' }}
        >
          event
        </Icon>
        <Box
          className="fc-daygrid-event-dot"
          sx={{ borderColor: 'var(--not-started-work)' }}
        />
        <ListItemText
          primary="A realizar"
          sx={{ color: 'var(--fc-event-text-color)' }}
        />
      </ListItem>
      <ListItem sx={{ width: 'auto', padding: '8px' }}>
        <Icon
          className="material-symbols-outlined"
          sx={{ fontSize: '16px', color: 'var(--started-work)' }}
        >
          timelapse
        </Icon>
        <Box
          className="fc-daygrid-event-dot"
          sx={{ fontSize: '16px', borderColor: 'var(--started-work)' }}
        />
        <ListItemText
          primary="Em andamento"
          sx={{ color: 'var(--fc-event-text-color)' }}
        />
      </ListItem>
      <ListItem sx={{ width: 'auto', padding: '8px' }}>
        <Icon
          className="material-symbols-outlined"
          sx={{ fontSize: '16px', color: 'var(--color-primary)' }}
        >
          task_alt
        </Icon>
        <Box
          className="fc-daygrid-event-dot"
          sx={{ borderColor: 'var(--color-primary)' }}
        />
        <ListItemText
          primary="Finalizado"
          sx={{ color: 'var(--fc-event-text-color)' }}
        />
      </ListItem>
      <ListItem sx={{ width: 'auto', padding: '8px' }}>
        <Icon
          className="material-icons"
          sx={{ fontSize: '16px', color: 'var(--reviewed-work)' }}
        >
          verified
        </Icon>
        <Box
          className="fc-daygrid-event-dot"
          sx={{ borderColor: 'var(--reviewed-work)' }}
        />
        <ListItemText
          primary="Revisado"
          sx={{ color: 'var(--fc-event-text-color)' }}
        />
      </ListItem>
      <ListItem sx={{ width: 'auto', padding: '8px' }}>
        <Icon
          className="material-symbols-outlined"
          sx={{ fontSize: '16px', color: 'var(--fc-late-event-text-color)' }}
        >
          slab_serif
        </Icon>
        <ListItemText
          primary="Atrasado"
          sx={{ color: 'var(--fc-event-text-color)', marginLeft: '4px' }}
        />
      </ListItem>
    </List>
  );
};

export default CalendarFooter;
