export const getCalendarWorks = async ({ urlParams }) => {
  let calendarEndpoint = '/works/calendar.json?';
  calendarEndpoint += urlParams.toString();

  const response = await fetch(calendarEndpoint);

  const contentType = response.headers.get('content-type');

  if (!contentType || !contentType.includes('application/json')) {
    throw new TypeError("Oops, we haven't got JSON!");
  }

  const responseJson = await response.json();

  if (!response.ok) {
    throw new Error(`Error ${response.status}: ${responseJson.error}`);
  }

  return responseJson;
}

export const updateWork = async ({ id, body }) => {
  const response = await fetch(`/works/${id}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });

  const responseJson = await response.json();

  if (!response.ok) {
    throw new Error(`Error ${response.status}: ${responseJson.error}`);
  }

  return responseJson;
}
